:root {
  --dark-100: #0d0d0d;
  --dark-90: #1c1c1c;
  --dark-80: #2b2b2b;
  --dark-70: #3a3a3a;
  --dark-60: #4a4a4a;
  --dark-50: #595959;
  --light-100: #ffffff;
  --light-90: #f0f0f0;
  --light-80: #e1e1e1;
  --light-70: #d2d2d2;
  --light-60: #c3c3c3;
  --light-50: #b3b3b3;
  --primery-100: #b49b56;
  --primery-90: #c1ac72;
  --primery-80: #cdbc8e;
  --primery-70: #dacdab;
  --primery-60: #e6dec7;
  --primery-50: #f3eee3;
  --lightgreen-100: #34c759;
  --lightgreen-60: #96e8aa;
  --lightgreen-20: #c6f2d2;
  --lightred-100: #ff3b30;
  --lightred-60: #ff9d98;
  --lightred-20: #ffcecb;
  --lightred-10: #ffe6e4;
  --lightred-darkgreen100: #30d158;
  --lightred-darkgreen60: #187c32;
  --lightred-darkgreen20: #0d3f19;
  --darkred-100: #ff453a;
  --darkred-60: #99251f;
  --darkred-20: #521511;
}
@font-face {
  font-family: 'َabar';
  src: url('../public/font/AbarFaNum-VF.woff2') format('woff2');
}
body {
  background-color: var(--light-100);
  margin: 0;
}

/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: white;
  border-radius: 24px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.01), 0px 9px 14px rgba(0, 0, 0, 0.01), 0px 15px 32px rgba(0, 0, 0, 0.016);
  width: 80%;
  max-height: 90%;
  max-width: 90%;
}
.modal-content img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--darkred-60);
  background-color: var(--lightred-10);
  border: 1px solid var(--lightred-60);
  font-size: 20px;
  border-radius: 30px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.header {
  overflow: hidden;
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(240, 240, 240, 0.7);
  backdrop-filter: blur(20px);
  border-bottom: 1px solid #3838381f;
}
.logo {
  margin: 20px 20px 13px 24px;
}
.gradient {
  background: linear-gradient(90deg, var(--light-90) 10%, #f0f0f000 100%);
  height: 32px;
  width: 30px;
  position: fixed;
  left: 0px;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;
  border-right: 1px solid var(--primery-70);
}
.nav-list {
  list-style-type: none;
  display: flex;
  gap: 10px;
  padding: 0px 12px;
}
.nav-item {
  padding: 0px 10px;
  background-color: #fdfdfd60;
  border-radius: 20px;
  border: 1px solid var(--light-60);
  text-align: center;
  font-family: 'َabar';
  font-variation-settings: 'wght' 600, 'CNTR' 40;
  font-size: 16px;
  transition: all 0.3s;
}
.nav-item:hover {
  background-color: #f3eee4c9;
  border: 1px solid var(--primery-100);
}

.footerPadding {
  width: 100%;
  height: 80px;
}
.footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 34px;
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(240, 240, 240, 0.7);
  backdrop-filter: blur(20px);
  box-sizing: border-box;
}
.footer.hidden {
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
}
.call {
  color: var(--dark-50);
  opacity: 0.8;
  font-family: 'َabar';
  font-size: 14px;
  font-variation-settings: 'wght' 400, 'CNTR' 40;
  text-decoration-line: none;
  line-height: 11px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 4px;
}
.insta {
  color: var(--dark-50);
  opacity: 0.8;
  font-family: 'َabar';
  font-size: 14px;
  font-variation-settings: 'wght' 400, 'CNTR' 40;
  text-decoration-line: none;
  line-height: 11px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 4px;
}
.location {
  color: var(--dark-50);
  opacity: 0.8;
  font-family: 'َabar';
  font-size: 14px;
  font-variation-settings: 'wght' 400, 'CNTR' 40;
  text-decoration-line: none;
  line-height: 11px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 4px;
}

.categoryTitle {
  color: var(--light-50);
  font-family: 'َabar';
  font-size: 40px;
  font-variation-settings: 'wght' 100, 'CNTR' 70;
  line-height: 140%;
  display: flex;
  margin-right: 16px;
  margin-top: 70px;
}

.allItems {
  width: 100%;
}
.menuItem {
  margin: 0px 10px;
  padding: 12px 8px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.54);
  border-radius: 8px;
  transition: all 0.3s;
  justify-content: space-between;
  align-items: center;
}
.menuItem:hover {
  background-color: var(--light-90);
}
.photoText {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
}
.foodPhoto {
  width: 54px;
  height: 54px;
  border-radius: 6px;
}
.titles {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.foodTitle {
  color: var(--dark-100);
  font-family: 'َabar';
  font-size: 16px;
  font-variation-settings: 'wght' 700, 'CNTR' 40;
}
.foodMaterial {
  color: var(--dark-50);
  font-family: 'َabar';
  font-size: 12px;
  font-variation-settings: 'wght' 400, 'CNTR' 40;
  line-height: 150%;
}
.priceInfo {
  justify-content: center;
  align-items: left;
  gap: 0px;
  display: flex;
  flex-direction: column;
}
.prices {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.price {
  color: var(--dark-100);
  font-family: 'َabar';
  font-size: 16px;
  font-variation-settings: 'wght' 1000, 'CNTR' 40;
  justify-content: space-between;
  align-items: center;
}
.infoIcon {
  width: 24px;
  height: 24px;
  background-color: var(--light-90);
  border: 1px solid var(--light-80);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ri-information-line {
  color: var(--dark-50);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeIcon {
  width: 24px;
  height: 24px;
  background-color: var(--lightred-10);
  border: 1px solid var(--lightred-60);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ri-close-line {
  color: var(--lightred-100);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* description style */
.bgDesc {
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: center;
  align-items: center;
  margin: 8px 10px 14px 10px;
  background-color: var(--primery-50);
  border-radius: 8px;
}
.arrowTop {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--primery-60);
  margin-top: -16px;
  margin-bottom: 8px;
}
.textDesc {
  color: var(--dark-100);
  font-family: 'َabar';
  font-size: 14px;
  font-variation-settings: 'wght' 500, 'CNTR' 40;
  text-align: justify;
}
